const capitalizeFirstLetter = (value) => {
  if (value) {
    const [firstLetter, ...rest] = value.split('')
    const upperCaseLetter = firstLetter.toUpperCase()

    if (firstLetter === upperCaseLetter) {
      return value
    }

    value = firstLetter.toUpperCase() + rest.join('')
  }

  return value
}
export {
  capitalizeFirstLetter,
}
